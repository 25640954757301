import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import find from "lodash.find"
import { isFuture } from "date-fns"

// Import Global Context(s)
import UserContext from "~context/user"

/* Import Global Components */
import Page from "~components/page"
import Link from "~components/link"

/* Import Local Styles */
import "./series.scss"

const SeriesTemplate = ({ data: { sanitySeries: series } }) => {
  const [isSeriesSaved, setIsSeriesSaved] = useState(false)
  const [user, { saveSeries, removeSeries }] = useContext(UserContext)

  useEffect(() => {
    if (user && series) {
      const isSeriesSaved =
        find(user.research.series, savedSeries => {
          return savedSeries.id === series.id
        }) !== undefined
      setIsSeriesSaved(isSeriesSaved)
    }
  }, [series, user])

  const handleSaveSeriesClick = () => {
    if (user) {
      saveSeries(series)
    }
  }

  const handleRemoveSeriesClick = () => {
    if (user) {
      removeSeries(series)
    }
  }

  const isPublished = post => {
    return !isFuture(new Date(post.publicationDate)) && post.publishOnWebsite
  }

  return (
    <Page title={series.title}>
      <div>
        <p>{series.title}</p>
      </div>
      <div>
        {/* if user has saved this post, show remove option */}
        {isSeriesSaved ? (
          <button onClick={handleRemoveSeriesClick}>Remove Series</button>
        ) : (
          <button onClick={handleSaveSeriesClick}>Save Series</button>
        )}
      </div>
      <br />
      <div>
        <BlockContent blocks={series._rawDescription} />
      </div>
      <br />
      <div>
        <p>Posts</p>
        {/* {series.posts.nodes.map(post => ( */}
        {series.posts.nodes.filter(isPublished).map(post => (
          <div key={post._id}>
            <Link to={post.slug}>{post.postTitle}</Link>
          </div>
        ))}
      </div>
    </Page>
  )
}

export default SeriesTemplate

export const query = graphql`
  query($id: String!) {
    sanitySeries(id: { eq: $id }) {
      id
      slug
      title
      _rawDescription
      posts {
        nodes {
          _id
          publicationDate
          postTitle
          publishOnWebsite
          slug
        }
      }
    }
  }
`
